
    import Vue from 'vue'

    import TimeRangeFilter from './TimeRangeFilter.vue';
    import StringFilter from './StringFilter.vue';
    import BooleanFilter from './BooleanFilter.vue';
    import ConnectorFilter from './ConnectorFilter.vue';
    import PropertyFilter from './PropertyFilter.vue';
    import DeviceFilter from './DeviceFilter.vue';
    import NumberFilter from './NumberFilter.vue';
    import TagFilter from './TagFilter.vue';
    import SelectionFilter from './SelectionFilter.vue';
    import TenantFilter from './TenantFilter.vue';
    import UserFilter from './UserFilter.vue';

    // item collections for selectionfilter
    import { SetpointStateTypes } from '../entities/setpoint/SetpointStateTypes';
    import { ReadingFlagTypes, ReadingAggregationFrequencies, ReadingAggregationFunctions } from '../entities/reading/ReadingCollections';
    import { UserPermissionRoles } from '../entities/user/UserCollections';
    import AggregationIntervalFilter from './AggregationIntervalFilter.vue';

    export default Vue.extend({
      name: 'EntityFiltersCard',
  
      components: {
        TimeRangeFilter,
        StringFilter,
        BooleanFilter,
        ConnectorFilter,
        PropertyFilter,
        DeviceFilter,
        NumberFilter,
        TagFilter,
        SelectionFilter,
        TenantFilter
      },
      props: {
        config: {
          type: Array,
          required: true
        },        
        defaults: {
          type: Object,
          default: () => {}
        },        
        value: {
          required: true
        },
      },
      data: (_this: any): any => ({
        valid: undefined,
        settings: [],
        toggleSelect: false,
        activeChanges: false,
        selectedComponents: [] as any[],
        localFilters: _this.value,
        components: {
          'timestamp': {
            component: TimeRangeFilter,
            attributes: { from: 'from', until: 'until' }
          },
          'createdAt': {
            component: TimeRangeFilter,
            attributes: { from: 'createdFrom', until: 'createdUntil' }
          },
          'email': {
            component: StringFilter,
            attributes: { value: 'email' }
          },
          'name': {
            component: StringFilter,
            attributes: { value: 'name' }
          },
          'aksId': {
            component: StringFilter,
            attributes: { value: 'aksId' }
          },
          'identifier': {
            component: StringFilter,
            attributes: { value: 'identifier' }
          },
          'street': {
            component: StringFilter,
            attributes: { value: 'street' }
          },
          'postalCode': {
            component: StringFilter,
            attributes: { value: 'postalCode' }
          },
          'city': {
            component: StringFilter,
            attributes: { value: 'city' }
          },
          'country': {
            component: StringFilter,
            attributes: { value: 'country' }
          },
          'localAksId': {
            component: StringFilter,
            attributes: { value: 'localAksId' }
          },
          'unit': {
            component: StringFilter,
            attributes: { value: 'unit' }
          },
          'serialnumber': {
            component: StringFilter,
            attributes: { value: 'serialnumber' }
          },
          'type': {
            component: SelectionFilter,
            attributes: { value: 'type' },
            props: { items: [
              { value: 'E32', name: 'E32' },
              { value: 'P42', name: 'P42' },
              { value: 'OTHER', name: 'OTHER' },
            ]}
          },
          'subtype': {
            component: StringFilter,
            attributes: { value: 'subtype' }
          },
          'value': {
            component: NumberFilter,
            attributes: { value: 'value' }
          },
          'flag': {
            component: SelectionFilter,
            attributes: { value: 'flag' },
            props: { items: ReadingFlagTypes }
          },
          'state': {
            component: SelectionFilter,
            attributes: { value: 'state' },
            props: { items: SetpointStateTypes }
          },
          'interval': {
            component: AggregationIntervalFilter,
            attributes: { value: 'aggregationInterval' },
          },
          'frequency': {
            component: SelectionFilter,
            attributes: { value: 'aggregationFrequency' },
            props: { items: ReadingAggregationFrequencies },
          },
          'function': {
            component: SelectionFilter,
            attributes: { value: 'aggregationFunction' },
            props: { items: ReadingAggregationFunctions },
          },
          'role': {
            component: SelectionFilter,
            attributes: { value: 'role' },
            props: { items: UserPermissionRoles }
          },
          'tag': {
            component: TagFilter,
            attributes: { value: 'tag' }
          },
          'deleted': {
            component: BooleanFilter,
            attributes: { value: 'deleted' }
          },
          'isRelative': {
            component: BooleanFilter,
            attributes: { value: 'isRelative' }
          },
          'alerting': {
            component: BooleanFilter,
            attributes: { value: 'alerting' }
          },
          'property': {
            component: PropertyFilter,
            attributes: { value: 'property' }
          },
          'connector': {
            component: ConnectorFilter,
            attributes: { value: 'connector' }
          },
          'device': {
            component: DeviceFilter,
            attributes: { value: 'device' }
          },
          'tenant': {
            component: TenantFilter,
            attributes: { value: 'tenant' }
          },
          'user': {
            component: UserFilter,
            attributes: { value: 'user' }
          },
          'enabled': {
            component: BooleanFilter,
            attributes: { value: 'enabled' }
          },
        } as any
      }),
      created() {
        this.selectedComponents = this.availableComponents.filter((value: any) => value.props.always || value.props.mandatory);
      },
      methods: {
        onAddFilterComponent(item: any) {
          this.selectedComponents.push(item);
          this.activeChanges = true;
        },
        onCloseFilterComponent(index: number) {
          this.selectedComponents.splice(index, 1);
          this.activeChanges = true;
        },
        reset() {
          this.localFilters = { ...this.defaults };
          this.selectedComponents = this.availableComponents.filter((value: any) => value.props.always || value.props.mandatory);
          this.activeChanges = true;
          this.$emit("reset");
        },
        apply() {
          if(this.filterForm.validate()) {
            this.activeChanges = false;
            this.$emit("input", this.localFilters);
            this.$emit("apply");
          }
        }
      },
      watch: {
        value() {
          this.localFilters = this.value;
          this.$nextTick(() => {
            // little hack to get to not show active changes when filter changed in parent
            this.activeChanges = false;
          });
        },
        localFilters() {
          this.activeChanges = true;
        },
        config() {
          this.selectedComponents = this.availableComponents.filter((value: any) => value.props.always || value.props.mandatory);
          this.localFilters = { ...this.defaults, ...this.localFilters };
        },
        currentTenant() {
          this.reset();
        }
      },
      computed: {
        filterForm(): any {
            return this.$refs.filterForm;
        },
        filteredAvailableComponents(): any[] {
          return this.availableComponents.filter((value: any) => !value.props.always && !value.props.mandatory);
        },
        availableComponents(): any[] {
          return this.config.map((value: any, index: number) => ({
            component: this.components[value.attribute].component, 
            props: { 
              ...this.components[value.attribute].props, 
              label: value.attribute, 
              attributes: this.components[value.attribute].attributes, 
              mandatory: value.mandatory??false, 
              always: value.always??false 
            }
          }));
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
      }
    })
  